@tailwind base;
@tailwind components;
@tailwind utilities;

/* https://stackoverflow.com/questions/71992334/how-to-remove-white-border-on-lazy-loaded-images-in-safari */
@supports (font: -apple-system-body) and (-webkit-appearance: none) {
	img[loading="lazy"] {
		clip-path: inset(0.6px);
	}
}

@layer base {
	* {
		@apply border-border scrollbar-thin scrollbar-track-black/10 scrollbar-thumb-rounded-full dark:scrollbar-thumb-white/40;
	}

	html {
		-webkit-tap-highlight-color: transparent;
		@apply overflow-x-hidden bg-dark-purple-200 bg-tiedie bg-cover bg-fixed bg-center dark:bg-tiedie-dark;
	}

	p,
	span {
		@apply text-dark-purple-100 dark:text-white;
	}

	body {
		@apply overflow-x-hidden;
		font-feature-settings:
			"rlig" 1,
			"calt" 1;
	}

	#beacon-container {
		@apply hidden sm:block;
	}
	:root {
		--background: 0 0% 100%;
		--foreground: 0 0% 3.9%;
		--card: 0 0% 100%;
		--card-foreground: 0 0% 3.9%;
		--popover: 0 0% 100%;
		--popover-foreground: 0 0% 3.9%;
		--primary: 0 0% 9%;
		--primary-foreground: 0 0% 98%;
		--secondary: 0 0% 96.1%;
		--secondary-foreground: 0 0% 9%;
		--muted: 0 0% 96.1%;
		--muted-foreground: 0 0% 45.1%;
		--accent: 0 0% 96.1%;
		--accent-foreground: 0 0% 9%;
		--destructive: 0 84.2% 60.2%;
		--destructive-foreground: 0 0% 98%;
		--border: 0 0% 89.8%;
		--input: 0 0% 89.8%;
		--ring: 0 0% 3.9%;
		--chart-1: 12 76% 61%;
		--chart-2: 173 58% 39%;
		--chart-3: 197 37% 24%;
		--chart-4: 43 74% 66%;
		--chart-5: 27 87% 67%;
		--radius: 0.5rem;

		--background: 0 0% 100%;
		--foreground: 222.2 47.4% 11.2%;

		--muted: 210 40% 96.1%;
		--muted-foreground: 215.4 16.3% 46.9%;

		--popover: 0 0% 100%;
		--popover-foreground: 222.2 47.4% 11.2%;

		--border: 214.3 31.8% 91.4%;
		--input: 214.3 31.8% 91.4%;

		--card: 0 0% 100%;
		--card-foreground: 222.2 47.4% 11.2%;

		--primary: 222.2 47.4% 11.2%;
		--primary-foreground: 210 40% 98%;

		--secondary: 210 40% 96.1%;
		--secondary-foreground: 222.2 47.4% 11.2%;

		--accent: 210 40% 96.1%;
		--accent-foreground: 222.2 47.4% 11.2%;

		--destructive: 0 100% 50%;
		--destructive-foreground: 210 40% 98%;

		--ring: 215 20.2% 65.1%;

		--radius: 0.5rem;
	}
	.dark {
		--background: 0 0% 3.9%;
		--foreground: 0 0% 98%;
		--card: 0 0% 3.9%;
		--card-foreground: 0 0% 98%;
		--popover: 0 0% 3.9%;
		--popover-foreground: 0 0% 98%;
		--primary: 0 0% 98%;
		--primary-foreground: 0 0% 9%;
		--secondary: 0 0% 14.9%;
		--secondary-foreground: 0 0% 98%;
		--muted: 0 0% 14.9%;
		--muted-foreground: 0 0% 63.9%;
		--accent: 0 0% 14.9%;
		--accent-foreground: 0 0% 98%;
		--destructive: 0 62.8% 30.6%;
		--destructive-foreground: 0 0% 98%;
		--border: 0 0% 14.9%;
		--input: 0 0% 14.9%;
		--ring: 0 0% 83.1%;
		--chart-1: 220 70% 50%;
		--chart-2: 160 60% 45%;
		--chart-3: 30 80% 55%;
		--chart-4: 280 65% 60%;
		--chart-5: 340 75% 55%;

		--background: 224 71% 4%;
		--foreground: 213 31% 91%;

		--muted: 223 47% 11%;
		--muted-foreground: 215.4 16.3% 56.9%;

		--accent: 216 34% 17%;
		--accent-foreground: 210 40% 98%;

		--popover: 224 71% 4%;
		--popover-foreground: 215 20.2% 65.1%;

		--border: 216 34% 17%;
		--input: 216 34% 17%;

		--card: 224 71% 4%;
		--card-foreground: 213 31% 91%;

		--primary: 210 40% 98%;
		--primary-foreground: 222.2 47.4% 1.2%;

		--secondary: 222.2 47.4% 11.2%;
		--secondary-foreground: 210 40% 98%;

		--destructive: 0 63% 31%;
		--destructive-foreground: 210 40% 98%;

		--ring: 216 34% 17%;

		--radius: 0.5rem;
	}
}

/** This layer automatically converts into proper Tailwind components */
@layer components {
	.icon-button {
		@apply flex items-center justify-center rounded-full border border-white/0 bg-white bg-opacity-20 p-3 transition-all hover:border-white/20 hover:bg-foil hover:shadow-lg active:border-white/20 active:bg-foil active:shadow-lg;
	}
	.hologram-preview-card {
		-webkit-tap-highlight-color: transparent;
		@apply flex cursor-pointer overflow-hidden rounded-lg transition-all duration-200 ease-out-quart;
	}

	.home-widget {
		animation: MoveUpDown 7.8s ease-in-out infinite;
	}

	.floating-widget {
		@apply home-widget pointer-events-none z-[3] select-none;
	}

	.lkg-black {
		color: #1f1e37;
	}

	.bg-holo-light {
		background: linear-gradient(58deg, #4ac7fa 0%, #e649f5 100%);
	}

	.holo-light {
		@apply bg-gradient-to-r from-[#4AC7FA] to-[#E649F5];
		/* background: linear-gradient(58deg, #4AC7FA 0%, #E649F5 30%); */
		@apply bg-clip-text text-transparent;
	}

	.bg-holo5-opaque-dark {
		background: linear-gradient(
			30deg,
			#f1dfc680 1.77%,
			#c1a4e890 16.58%,
			#b8e2fb70 49.82%,
			#f2efe860 67.95%,
			#f9dcdd80 86.08%,
			#e1c1e580 98.47%
		);
	}

	.bg-holo5-opaque {
		background: linear-gradient(
			30deg,
			#f1dfc610 1.77%,
			#c1a4e830 16.58%,
			#b8e2fb20 49.82%,
			#f2efe830 67.95%,
			#f9dcdd40 86.08%,
			#e1c1e580 98.47%
		);
	}

	.bg-holo5 {
		background: linear-gradient(
			61deg,
			#f1dfc6 1.77%,
			#c1a4e8 16.58%,
			#b8e2fb 49.82%,
			#f2efe8 67.95%,
			#f9dcdd 86.08%,
			#e1c1e5 98.47%
		);
	}

	.holo5 {
		/* please update the gradient in HomeBanner.tsx to match this if you change it. */
		background: linear-gradient(
			61deg,
			#f1dfc6 1.77%,
			#c1a4e8 16.58%,
			#b8e2fb 49.82%,
			#f2efe8 67.95%,
			#f9dcdd 86.08%,
			#e1c1e5 98.47%
		);
		/* @apply bg-clip-text text-transparent; */
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-box-decoration-break: clone;
		-webkit-text-fill-color: transparent;
		box-decoration-break: clone;
		text-shadow: none;
	}

	span.emoji {
		-webkit-text-fill-color: initial;
	}

	.bg-purple-fade {
		@apply bg-gradient-to-r from-[#5901FB] to-[#5901FB];
	}

	.bg-purple-grad {
		background: radial-gradient(76.09% 1304.32% at 87.24% 100%, #a055fa 0%, #5f15e8 100%);
	}

	.text-shadow {
		text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.8);
	}

	.container-button {
		/* light mode */
		@apply border-gray-200;
		@apply hover:bg-holo5 hover:border-[#E649F5] hover:border-opacity-40 hover:drop-shadow-lg;

		/* dark mode */
		@apply dark:border-[#60547E] dark:from-[#38295E] dark:to-[#38295E] dark:hover:border-[#ffffff30] dark:hover:from-[#4816A9] dark:hover:to-[#6639A9];
		@apply dark:!bg-gradient-to-r;

		/* global */
		@apply cursor-pointer place-items-center rounded-xl border-2 transition-all duration-100;
		@apply hover:scale-[1.02] active:translate-y-1;
	}

	.bg-purple-gradient {
		@apply bg-gradient-to-l from-[#5901FB] to-[#9E53FB];
	}

	.primaryButton {
		@apply rounded-md bg-gradient-to-l from-[#5901FB] to-[#9E53FB] bg-[length:200%_100%] bg-[position:100%_0] p-3 font-bold text-white transition-all duration-300 md:px-5 md:py-3;
		@apply group-hover:bg-[position:0_0] hover:bg-[position:0_0];
		@apply active:from-[#9E53FB] active:to-[#5901FB];
		@apply disabled:cursor-not-allowed disabled:bg-gradient-to-l disabled:from-[#5e5e5e] disabled:to-[#252525] disabled:text-white/50;
	}

	.primaryButtonAlt {
		@apply rounded-md bg-gradient-to-l from-slate-500 to-slate-300 bg-[length:200%_100%] bg-[position:100%_0] p-3 font-bold transition-all duration-300 hover:bg-[position:0_0] md:px-5 md:py-3;
		@apply text-white;
	}
	.errorButton {
		@apply rounded-md bg-gradient-to-l from-[#E22B12] to-[#FF3737] bg-[length:200%_100%] bg-[position:100%_0] p-3 font-bold text-white transition-all duration-300 md:px-5 md:py-3;
		@apply group-hover:bg-[position:0_0] hover:bg-[position:0_0];
		@apply active:from-[#E22B12] active:to-[#FF3737];
		@apply disabled:cursor-not-allowed disabled:bg-gradient-to-l disabled:from-[#5e5e5e] disabled:to-[#252525] disabled:text-white/50;
	}

	.cancelButton {
		@apply rounded-lg bg-white/10 p-3 font-medium hover:bg-white/40 md:px-5 md:py-3 dark:text-white;
	}

	.slider-range {
		-webkit-appearance: none;
		width: 100%;
		height: 8px;
		background: linear-gradient(90deg, white 50%, grey 50%);
		outline: none;
		opacity: 1;
		border-radius: 8px;
		-webkit-transition: 0.2s;
		transition: opacity 0.2s;
	}

	/* .slider-range::-webkit-slider-runnable-track {
		background-color: white;
		opacity: 40%;
	} */

	.slider-range::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		width: 24px;
		height: 24px;
		border: 2px solid white;
		background: radial-gradient(76.09% 1304.32% at 87.24% 100%, #a055fa 0%, #5f15e8 100%);
		cursor: pointer;
		border-radius: 100%;
		box-shadow:
			-6px 8px 8px -4.5px rgba(0, 0, 0, 0.16),
			0px 0px 12px 1px rgba(0, 0, 0, 0.14);
	}

	.slider-range::-moz-range-thumb {
		appearance: none;
		width: 24px;
		height: 24px;
		border: 2px solid white;
		background: radial-gradient(76.09% 1304.32% at 87.24% 100%, #a055fa 0%, #5f15e8 100%);
		cursor: pointer;
		border-radius: 100%;
		box-shadow:
			-6px 8px 8px -4.5px rgba(0, 0, 0, 0.16),
			0px 0px 12px 1px rgba(0, 0, 0, 0.14);
	}

	/* TODO dont blanket apply to inputs - use classes */
	.field {
		@apply w-full rounded-lg border-2 border-[#00000010] bg-white py-3 pl-[2.5ch] pr-3 text-lg leading-tight text-gray-700 outline-none transition-all focus:border-violet-300;
		@apply dark:border-[#ffffff10] dark:bg-[#30343D] dark:text-white dark:focus:border-purple-600;
	}

	.field.disabled {
		@apply bg-gray-100 text-gray-400;
		@apply dark:bg-[#30343D50] dark:text-gray-500;
	}

	.lkg-container {
		@apply rounded-lg border border-[#ffffff10] bg-white !bg-opacity-60 p-5 text-gray-700 shadow-md md:p-6 dark:bg-dark-purple-100 dark:text-white;
	}

	.lkg-container-modal {
		@apply glass rounded-lg !bg-opacity-100 p-5 text-gray-700 shadow-md md:p-6 dark:text-white;
	}

	.lkg-container-modal-solid {
		@apply solidGlass rounded-lg !bg-opacity-100 p-5 text-gray-700 shadow-md md:p-6 dark:text-white;
	}

	.link {
		@apply cursor-pointer text-light-purple transition-colors hover:text-fuchsia-600;
	}

	.link .icon {
		@apply ml-1 transition-all;
	}

	.scrollbox-light {
		background:
			/* Shadow covers */
			linear-gradient(rgb(255 255 255) 30%, rgba(255, 255, 255, 0)),
			linear-gradient(rgba(255, 255, 255, 0), rgb(255 255 255) 70%) 0 100%,
			/* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
			radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;

		background-repeat: no-repeat;
		background-size:
			100% 40px,
			100% 40px,
			100% 14px,
			100% 14px;

		/* Opera doesn't support this in the shorthand */
		background-attachment: local, local, scroll, scroll;
	}

	.scrollbox-dark {
		background:
			/* Shadow covers */
			linear-gradient(rgb(31 30 55) 30%, rgba(255, 255, 255, 0)),
			linear-gradient(rgba(255, 255, 255, 0), rgb(31 30 55) 70%) 0 100%,
			/* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
			radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)) 0 100%;

		background-repeat: no-repeat;
		background-size:
			100% 40px,
			100% 40px,
			100% 14px,
			100% 14px;

		/* Opera doesn't support this in the shorthand */
		background-attachment: local, local, scroll, scroll;
	}

	.holo9 {
		background: linear-gradient(90deg, rgba(74, 199, 250, 1) 0%, rgba(230, 73, 245, 1) 100%);
	}

	.glass {
		position: relative;
		border: 2px solid rgba(255, 255, 255, 0.18);
		/* backdrop-filter: blur(17.7px);
		-webkit-backdrop-filter: blur(17.7px); */
		background: linear-gradient(152deg, rgba(255, 255, 255, 0.5) 17.43%, rgba(144, 124, 188, 0.3) 100%);
		box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.25);
		backdrop-filter: blur(17.7px);
	}

	/* .glass:before {
		content: "";
		position: absolute;
		z-index: -1;
		-webkit-backdrop-filter: blur(24px);
			backdrop-filter: blur(24px);
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
	} */

	.solidGlass {
		border-radius: 8px;
		border: 2px solid rgba(255, 255, 255, 0.1);
		backdrop-filter: blur(17.7px);
		-webkit-backdrop-filter: blur(17.7px);
		background:
			linear-gradient(152deg, rgba(255, 255, 255, 1) 17.43%, rgb(180, 162, 220) 100%),
			url("/public/glass_noise_texture.png");
		/* lightgray 0% 0% / 100px 100px repeat; */
		box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.25);
	}

	.library-fade {
		background:
			linear-gradient(180deg, rgba(117, 114, 207, 0.4) 50%, #1f1e37 100%),
			linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%);
	}

	@media (prefers-color-scheme: dark) {
		.library-fade {
			background:
				linear-gradient(180deg, rgba(31, 30, 55, 0.4) 50%, #1f1e37 100%),
				linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%);
		}
	}

	@media (prefers-color-scheme: dark) {
		.solidGlass {
			background: linear-gradient(152deg, rgb(69, 67, 110) 0%, rgb(56, 42, 84) 82.57%);
		}
	}

	@media (prefers-color-scheme: dark) {
		.glass {
			background: linear-gradient(152deg, rgba(106, 104, 168, 0.5) 17.43%, rgba(89, 67, 134, 0.3) 100%);
		}
	}
}

h1,
h2,
h3,
h4,
h5 {
	@apply holo-light dark:holo5 font-rubik;
}

/* Used for storybook */
.lkg-body {
	@apply bg-dark-purple-200 bg-tiedie bg-cover bg-fixed bg-center dark:bg-tiedie-dark;
}

.lkg-layout {
	@apply font-sans subpixel-antialiased;
	background-size: 100% 100%;
}

.table-container {
	@apply rounded-lg border border-[#ffffff10] bg-white shadow-md dark:bg-dark-purple-100 dark:bg-opacity-60;
}

.table {
	@apply w-full table-auto;
}

.table tr td {
	@apply px-2 py-2 text-black transition-colors dark:text-white;
}

.table tr:first-child td:first-child {
	@apply rounded-tl-lg;
}

.table tr:first-child td:last-child {
	@apply rounded-tr-lg;
}

.table tr:last-child td:first-child {
	@apply rounded-bl-lg;
}

.table tr:last-child td:last-child {
	@apply rounded-br-lg;
}

.table tr:hover td {
	@apply bg-gray-100 dark:bg-[#ffffff10];
}

.table tr td:first-child {
	@apply pl-4;
}

.table tr td:last-child {
	@apply pr-4;
}

.table tr:first-child td {
	@apply pt-4;
}

.table tr:last-child td {
	@apply pb-4;
}

.delay30 {
	animation-delay: 1s;
}

.delay50 {
	animation-delay: 4s;
}

.delay70 {
	animation-delay: 7s;
}

@keyframes MoveUpDown {
	0%,
	100% {
		transform: translateY(0);
	}

	50% {
		transform: translateY(-9px);
	}
}

div[data-radix-popper-content-wrapper] {
	pointer-events: none;
}
div[data-radix-popper-content-wrapper]:focus-visible {
	outline: none;
}

#nprogress .peg {
	box-shadow: none !important;
}

.drop-shadow-icon {
	filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.75));
}

.bg-glass {
	background-image: url("/noise.svg");
}

@layer utilities {
	.backdrop-hack {
		@apply absolute inset-0 -z-10;
	}
}

.gradient-box {
	mask-image:
		linear-gradient(90deg, black 0%, transparent 5%), linear-gradient(270deg, black 0%, transparent 5%),
		linear-gradient(0deg, black 0%, transparent 5%), linear-gradient(180deg, black 0%, transparent 5%);
	/* -webkit-mask-image: linear-gradient(90deg, black 0%, transparent 5%),
		linear-gradient(270deg, black 0%, transparent 5%), linear-gradient(0deg, black 0%, transparent 5%),
		linear-gradient(180deg, black 0%, transparent 5%); */
	mask-composite: exclude;
}
